// This Responsive Typography demo is to show the simplier syntax
//
// It asks just for the min size and the max size
// h1 { @include responsive-type(22px, 38px); }
//
// Built of the back of the authors of:
// http://www.sassmeister.com/gist/7f22e44ace49b5124eec
// https://codepen.io/stowball/pen/JWoWWz?editors=0100

// As most min-width values will be 320px and the max value often will be the max-width of the container
// We set "smart" default values
$responsive-type-min-width: 480px !default;
$responsive-type-max-width: 1600px !default;

// $baseline: 1.5rem;
$baseline: 1.414em;

// Base Responsive resize function that the type one uses
@mixin responsive-resize($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

// Type Focus helper mixin for the resize function
@mixin responsive-type($min-value, $max-value, $properties: font-size) {
  // use the global default values based on the assumption these
  // will be contained in a block
  $min-vw: $responsive-type-min-width;
  $max-vw: $responsive-type-max-width;

  // check if value is using the "@" api syntax.
  @if ( type-of($min-value) == "string") {
    // So it is a string does it have a "@" symbol to break apart
    @if ( str-index($min-value, '@') ) {
      // split string helper
      $min-value-list : split-str($min-value, '@');
      // unquote the split and cast the string to a number
      // http://hugogiraudel.com/2014/01/15/sass-string-to-number/
      $min-value: to-number( unquote( nth($min-value-list, 1) ) );
      $min-vw: to-number( unquote( nth($min-value-list, 2) ));
    }
  }

  // run the same checks over the $max-value
  @if ( type-of($max-value) == "string") {
    // So it is a string does it have a "@" symbol to break apart
    @if ( str-index($max-value, '@') ) {
      $max-value-list : split-str($max-value, '@');
      $max-value: to-number( unquote( nth($max-value-list, 1) ) );
      $max-vw: to-number( unquote( nth($max-value-list, 2) ));
    }
  }

  // feed the shorter api back to the responsice resizer
  @include responsive-resize($properties, $min-vw, $max-vw, $min-value, $max-value);
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

/*
  Single property.
  with min and max passed.
*/
// h1 {
//   @include responsive-type(22px, 38px);
// }

/* An example of adding another property in such as line height */
// h2 {
//   @include responsive-type(18px, 28px);
//   @include responsive-type(1.2, 1.05, line-height);
// }

/* An example of the custom breakpoints for '@' redable syntax */
/* This is to avoid the map approach here: https://codepen.io/stowball/pen/JWoWWz */

// h3 {
//   @include responsive-type('14px@400px', '50px@1900px');
//   @include responsive-type('1.2@400px', '1.05@1900px', line-height);
// }

:root {
  // @include responsive-type(18px, 24px);
}

html {
	box-sizing: border-box;
	height: 100%;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {
	line-height: $baseline;
}

p {
  @include responsive-type(18px, 24px);
	line-height: $baseline;
	margin-bottom: $baseline;
}

.larger-text p {
  @include responsive-type(20px, 24px);
  line-height: 1.5;
  margin-bottom: $baseline;
}

@media (min-width: 1680px) {
  .larger-text p {
    line-height: 1.625;
  }
}

h1,
h2,
h3,
h4,
h5{
	margin-bottom: $baseline;
}

// 5.653 = 90.448
// 3.998 = 63.968
// 2.827 = 45.232
// 1.999 = 31.984
// 1.414 = 22.624
// 1 = 16


// 2.441 = 39.056
// 1.953 = 31.248
// 1.563 = 25.008
// 1.25 = 20
// 1 = 16


h1 {
  @include responsive-type(39.056px, 63.968px); // major third (1.25) to augmented 4th (1.414)
  @include responsive-type(1.25, 1.414, line-height);
}

h2 {
  @include responsive-type(31.248px, 45.232px); // major third (1.25) to augmented 4th (1.414)
  @include responsive-type(1.25, 1.414, line-height);
}
h3 {
  @include responsive-type(25.008px, 31.984px); // major third (1.25) to augmented 4th (1.414)
  @include responsive-type(1.25, 1.414, line-height);
}
h4 {
  // Perfect 4th 1.333 --  @include responsive-type(20.16px, 28.432px);
  @include responsive-type(20px, 22.624px); // major third (1.25) to augmented 4th (1.414)
  @include responsive-type(1.25, 1.414, line-height);
}
h5 {
  // @include responsive-type(18px, 24px);
  @include responsive-type(18px, 22.624px); // major third (1.25) to augmented 4th (1.414)
  @include responsive-type(1.25, 1.414, line-height);
}

.book-about-title {
  @include responsive-type(22px, 30px); // Augmented 4th 1.414
  @include responsive-type(1.25, 1.414, line-height);
}

@media (min-width: 1800px) {
  .book-about-title {
    @include responsive-type(25.008px, 39.056px); // Augmented 4th 1.414
    @include responsive-type(1.2, 1.414, line-height);
  }
}

.nav-logo {
  @include responsive-type(24px, 54px);
  @include responsive-type(1, 1, line-height);
  margin-bottom: 0;
}
a.nav-link {
  @include responsive-type(17px, 21px);
  @include responsive-type(1, 1, line-height);
}

.btn {
  @include responsive-type(18px, 24px);
  @include responsive-type(1, 1, line-height);
}

.btn-sm {
  @include responsive-type(15px, 17px); // Augmented 4th 1.414
  @include responsive-type(1, 1, line-height);
}


.praise-quote p {
  @include responsive-type(16px, 22.624px); // major third (1.25) to augmented 4th (1.414)
  @include responsive-type(1.25, 1.414, line-height);
}
